











































































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import store from "@/store";
//  types
import { ExtendedVessel } from "@/types/Vessel";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import DataQualityModule from "@/store/clients/DataQuality.module";

const Vessels = getModule(VesselsModule, store);
const DataQuality = getModule(DataQualityModule, store);

@Component({
  components: {},
})
export default class ManageOutliersTable extends Vue {
  @PropSync("selected", { type: Array, default: () => [] }) selectedRows!: Object[];
  @PropSync("filterByVessels") syncFilterByVessels!: ExtendedVessel[];
  @PropSync("filterByTags") syncFilterByTags!: string[];
  @Prop({ type: Boolean, default: false }) loading!: Boolean;
  @Prop({ type: Array, default: () => [] }) selection!: Object[];
  @Prop({ type: Array, default: () => [] }) headers!: Object[];
  @Prop({ type: Array, default: () => [] }) tagsList!: Object[];
  @Prop({ type: Array, default: () => [] }) vesselIds!: Object[];
  item: any = {};
  isVesselFilterEnabled = false;
  pageLimit = 10;

  //  @Getters
  get vessels(): ExtendedVessel[] {
    return Vessels.extendedVessels.filter(vessel => this.vesselIds.includes(vessel.id));
  }

  get isLessThanLimit(): boolean {
    return this.selection.length <= this.pageLimit;
  }

  get dataTagList(): any[] {
    return this.tagsList;
  }

  get filtredRows(): Object[] {
    const filtered = this.selection.filter((row: any) => {
      const vesselMatch = this.syncFilterByVessels.length === 0 || this.syncFilterByVessels.some(vesselId => vesselId === row.vesselId);
      const tagMatch = this.syncFilterByTags.length === 0 || this.syncFilterByTags.some(tag => tag === row.featureName);
      return vesselMatch && tagMatch;
    });
    return filtered;
  }

  //  @Methods
  featureUnit(featureName: string): string {
    return DataQuality.featureValues.find(feature => feature.name === featureName)?.unit.caption || "n/a";
  }

  clearVesselSelection(): void {
    this.syncFilterByVessels = [];
  }

  clearFeatureSelection(): void {
    this.syncFilterByTags = [];
  }
}
